import { t } from "@lingui/macro";
import { message } from "antd";
import axios, { AxiosPromise, AxiosResponse } from "axios";
import dayjs from "dayjs";
import { isEmpty, isNil, get as lodashGet, omitBy } from "lodash";
import moment from "moment";
import { BareFetcher } from "swr";

import { SWRInfiniteKeyLoader } from "swr/infinite";
import { parseDate } from "utils/datetime-utils";
import { parseErrorResponse } from "utils/errors-utils";
import { getCursors } from "utils/link-headers";
import { httpStatusCodes } from "utils/status-codes-utils";

/** improve http client to also handles server side errors or failures, also handle redirection to default error page
 * Support generic types so response is validated */

const API_URL = process.env.NEXT_PUBLIC_API_URL;
const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION;
const TIMEOUT = 30000;
const THROTTLE_DELAY = 60;
const THROTTLE_DELAY_UNIT = "seconds";

const throttleRequest = (path, throttle) => {
  throttle[path] = parseDate().add(THROTTLE_DELAY, THROTTLE_DELAY_UNIT);
};

const redirectToLogin = (err) => {
  if (location.pathname === "/login") {
    return;
  }

  if (err?.response?.status === httpStatusCodes.UNAUTHORIZED) {
    window.location.href = "/login?redirect=false";
  }
};
const isRequestThrottled = (path, throttle) => {
  const delay = throttle[path];
  if (isEmpty(delay)) {
    return false;
  }

  if (delay.isBefore(parseDate())) {
    throttle[path] = undefined;
    return false;
  }

  return true;
};

const throttle = async (fn, path, throttled) => {
  let idxPath = "";

  if (path.startsWith("https://")) {
    idxPath = `/${new URL(path).pathname.split("/")[1]}/`;
  } else {
    idxPath = `/${path.split("/")[1]}/`; // picks the first element of the base path
  }

  if (isRequestThrottled(idxPath, throttled)) {
    console.warn(
      `Request not sent because path: ${idxPath} has been throttled, service available ${moment(
        throttled[idxPath].toString()
      ).fromNow()}`
    );
    return Promise.reject();
  }

  try {
    return await fn();
  } catch (e) {
    switch (e?.response?.status) {
      case httpStatusCodes.TOO_MANY_REQUESTS:
        throttleRequest(idxPath, throttled);
        message.error(t`Too many requests. Service will be available in a minute`);
      /**
       * TODO: add more error case, special case for 401's as we might not want to redirect to login always
       * Current UI behaviour handles 429 as 404. should fix?
       * https://trello.com/c/TaF4FNz9/3152-prompt-to-update-frontend-app-if-new-build-exist (Error handling and timeouts)
       */
    }
    return Promise.reject(e);
  }
};

export interface HttpClient {
  readonly basePath: string;
  readonly headers: { [key: string]: string };
  readonly apiVersion: string;

  readonly get: (path, params?) => Promise<AxiosResponse>;
  readonly options: (path, params?) => AxiosPromise;
  readonly patch: (path, data) => Promise<AxiosResponse>;
  readonly post: (path, data, config?) => Promise<AxiosResponse>;
  readonly put: (path, data) => Promise<AxiosResponse>;
  readonly remove: (path) => Promise<AxiosResponse>;
  readonly fetchAll: (url: string, options?: { sideloadOrders?: boolean }) => Promise<{ data: any; metadata: any }>;
  readonly request: (
    url,
    method,
    data,
    params,
    headers?: {
      [key: string]: string;
    },
    responseType?: string
  ) => AxiosPromise;
  readonly swrFetcher: BareFetcher<any>;
  readonly swrInfiniteFetcher: BareFetcher<any>;
  readonly bareFetcher: BareFetcher<any>;
  readonly getKey: (url: string | undefined, preset?: "next" | "prev") => SWRInfiniteKeyLoader;
}

const httpClient = (token?: string, language?: string): HttpClient => {
  const defaultHeaders = {
    Authorization: token && `Token ${token}`,
    Accept: `application/json; version=${API_VERSION}`,
    "Accept-Language": language,
  };
  const throttled: { [key: string]: dayjs.Dayjs } = {};

  const client = axios.create({
    baseURL: API_URL,
    timeout: TIMEOUT,
    headers: defaultHeaders,
  });

  const get = async (path, params = null) => {
    return throttle(() => client.get(path, params && { params }), path, throttled);
  };

  const options = async (path, params = null) => {
    return throttle(() => request(path, "OPTIONS", null, params, null, null), path, throttled);
  };

  const patch = async (path, data) => {
    return throttle(() => client.patch(path, data), path, throttled);
  };

  const post = async (path, data, config) => {
    return throttle(() => client.post(path, data, config), path, throttled);
  };

  const put = async (path, data) => {
    return throttle(() => client.put(path, data), path, throttled);
  };

  const remove = async (path) => {
    return throttle(() => client.delete(path), path, throttled);
  };

  const request = (url, method, data, params, headers = null, responseType) => {
    const request = {
      method: method,
      url: url,
      headers: headers ? { ...defaultHeaders, ...omitBy(headers, isNil) } : defaultHeaders,
      ...(data && { data }),
      ...(params && { params }),
      ...(responseType && { responseType: responseType }),
    };
    return axios(request);
  };

  const swrFetcher = (url) =>
    get(url)
      .then((res) => res.data)
      .catch((err) => {
        redirectToLogin(err);
        parseErrorResponse(err);
        return null;
      });

  const swrInfiniteFetcher = async (url: string) => {
    try {
      const response = await get(url);
      const cursors = response.headers?.link ? getCursors(response.headers.link) : {};
      return { data: response.data, cursors, status: response.status, url };
    } catch (err) {
      redirectToLogin(err);
      parseErrorResponse(err);
      return { data: null, status: err?.response?.status };
    }
  };

  const fetchAll = async (url: string, options?: { sideloadOrders?: boolean }) => {
    let response = await get(url);
    let nextLink = response.headers?.link ? getCursors(response.headers.link)?.next : null;

    let _data = response?.data;

    while (nextLink) {
      response = await get(nextLink);
      nextLink = response.headers?.link ? getCursors(response.headers.link)?.next : null;

      if (options?.sideloadOrders) {
        _data = {
          orders: [..._data.orders, ...response.data.orders],
          tasks: [..._data.tasks, ...response.data.tasks],
        };
      } else {
        _data = [..._data, ...response.data];
      }
    }

    return { data: _data, metadata: { status: response.status, url } };
  };

  const bareFetcher = async (url: string) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err.response.data);
      return;
    }
  };

  const getKey =
    (url: string | undefined, preset: "next" | "prev" = "next") =>
    (index: number, prev: object): string | undefined => {
      if (prev) return lodashGet(prev, `cursors.${preset}.url`, "");

      return url;
    };

  return {
    basePath: API_URL,
    headers: defaultHeaders,
    apiVersion: API_VERSION,

    fetchAll,
    get,
    options,
    patch,
    post,
    put,
    remove,
    request,
    swrFetcher,
    swrInfiniteFetcher,
    bareFetcher,
    getKey,
  };
};

export default httpClient;
export { API_URL, API_VERSION };

// TODO: move to utils.
// TODO: add retry interceptors
