import { useRef, useState } from "react";

import { t } from "@lingui/macro";
import { message } from "antd";
import { get, isEmpty, keyBy, last } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import useStoreFilters from "components/dashboard/hooks/store-filters";
import useTasksFetcher from "components/dashboard/hooks/tasks-fetcher";
import { onGroupTasks } from "components/dashboard/utils";
import { useAppContext } from "contexts/app";
import { useUnload } from "hooks/unload";
import { AccountRole, Order, Route, Task } from "types/type";

const RELOAD_INTERVAL = 10 * 1000;
const MAX_PAGE_SIZE = 10000;
const UPDATED_AT_KEY = "updated_at__gt";
const UPDATED_AT_DELAY_KEY = "updated_at_delay_latest";
export const DEFAULT_MANAGED_TASKS_STATES = ["unassigned", "assigned", "accepted", "transit", "active", "failed"];
export const SCHEDULE_TASK_STATE = ["scheduled"];
export const DEFAULT_UNMANAGED_TASKS_STATES = ["completed", "cancelled", "scheduled"];

export enum TASK_STATE {
  MANAGED = "managed",
  UNMANAGED = "un-managed",
}

interface Tasks {
  readonly isLoading: boolean;
  readonly filters: any;
  readonly tasks: TasksGroups[];
  readonly metaFieldChoices: { [key: string]: { [key: string]: null } };

  onMutateNewTasks: (tasks: Task[], orders: Order[]) => void;
  onMutateGroupedTasks: () => void;
}

export interface TasksGroups {
  assignee: AccountRole;
  tasks: Task[];
  routes?: Route[];
  tasksCount?: number | string;
}

/*
  This custom hooks handles remote tasks fetching and creates a local store to reduce load for API calls.
  First load recursively fetches all task based on managed states DEFAULT_MANAGED_TASKS_STATES until limit $MAX_PAGE_SIZE is reached.
*/

const useFetchTasks = ({
  filterKey,
  override,
  routes,
  workers,
  loadingRoutes,
}: {
  filterKey: string;
  workers: AccountRole[];
  loadingRoutes?: boolean;
  routes: { [key: string]: Route };
  override: any;
}): Tasks => {
  const { appData, configuration, httpClient } = useAppContext();

  const [cachedTasks, setCachedTasks] = useState({});
  const [metaFieldChoices, setMetaFieldChoices] = useState({});
  const [isPolling, setIsPolling] = useState(false);

  const sideLoadOrders = get(configuration, "features.task_templates_orderer");
  const hideUnassignedTasks =
    get(configuration, "features.show_unassigned_to_workers") === false && appData.isUserOnlyAWorker;

  const baseURL = `${httpClient.basePath}/tasks/?account=${appData.accountId}&ordering=updated_at,id&${UPDATED_AT_DELAY_KEY}=true&sideload=orders`;
  const initialLoadURL = new URL(`${baseURL}&page_size=${MAX_PAGE_SIZE}&state__in=${DEFAULT_MANAGED_TASKS_STATES}`);

  const [tasksURL, setTasksURL] = useState(initialLoadURL);
  const [cancelledTasks, setCancelledTasks] = useState(null);
  const [completedTasks, setCompletedTasks] = useState(null);
  const [tasksLoading, setTasksLoading] = useState(true);

  const unManagedTasksFetchUrls = useRef({ completed: null, cancelled: null });

  const tasksURLHasUpdatedAt = tasksURL && tasksURL.searchParams.has(UPDATED_AT_KEY);

  const startPoller = tasksURLHasUpdatedAt && isPolling;
  const { data, reset } = useTasksFetcher(
    baseURL && !loadingRoutes && tasksURL.href,
    httpClient.swrInfiniteFetcher,
    startPoller ? RELOAD_INTERVAL : 0
  );

  const mutateTasks = () => {
    setTasksLoading(true);
    setTasksURL(initialLoadURL);
    reset();
    setCachedTasks({});
  };

  // handle tasks caching
  useDeepCompareEffect(() => {
    if (data === undefined || tasksURL.toString() !== data.url) {
      return;
    }
    let isCacheModified = false;

    const managedTasks = data?.data?.tasks || [];
    const unManagedTasks = [...(completedTasks?.tasks || []), ...(cancelledTasks?.tasks || [])];
    const unManagedTasksOrders = [...(completedTasks?.orders || []), ...(cancelledTasks?.orders || [])];
    const orders = keyBy([...(data?.data?.orders || []), ...unManagedTasksOrders], "url");

    const tasks = [...managedTasks, ...unManagedTasks];

    if (isEmpty(tasks)) {
      setTasksLoading(false);
      return;
    }

    if (isPolling) {
      message.destroy();
      message.loading(t`Syncing tasks...`);
    }

    tasks.forEach((task) => {
      isCacheModified = true;
      Object.keys(task?.metafields).forEach((key: string) => {
        metaFieldChoices[key] = { ...metaFieldChoices[key], [task?.metafields[key]]: null };
      });

      if (sideLoadOrders) {
        task["order"] = orders[task.order];
      }
      cachedTasks[task.id] = task;
    });

    if (isCacheModified) {
      setCachedTasks({ ...cachedTasks });
      setMetaFieldChoices({ ...metaFieldChoices });

      if (data?.cursors?.next) {
        setTasksURL(new URL(data?.cursors?.next));
        setIsPolling(false);
        message.destroy();
        return;
      }

      const lastTask: Task = last(managedTasks);
      if (lastTask && !data?.cursors?.next) {
        setTasksLoading(false);

        const url = new URL(baseURL);

        let date = lastTask.updated_at;
        url.searchParams.set(UPDATED_AT_KEY, date);

        setTasksURL(new URL(url.toString()));
        setIsPolling(true);
      }
    }
  }, [{ data, cancelledTasks, completedTasks }]);

  const onMutateNewTasks = (tasks, orders) => {
    tasks.forEach((task) => {
      const newTask = { ...task };

      if (sideLoadOrders) {
        newTask["order"] = keyBy(orders, "url")[newTask["order"]];
      }
      cachedTasks[task?.id] = Object.assign(cachedTasks[task?.id] || {}, newTask);
    });
    setCachedTasks({ ...cachedTasks });
  };

  // handle search
  const onSearch = (params) => {
    setTasksLoading(true);

    let url = new URL(baseURL);
    if (isEmpty(params)) {
      url = new URL(initialLoadURL);
    } else {
      url.searchParams.set("search", params);
    }

    setTasksURL(new URL(url.toString()));
    setCachedTasks({});
  };

  // handle API calls filters
  const onApplyFilters = async (filters) => {
    setTasksLoading(true);

    await Promise.all(
      Object.keys(filters).map(async (key) => {
        const url = new URL(baseURL);
        filters[key].forEach((f) => {
          // {key, condition, value}
          url.searchParams.set(f.key.concat(f.condition), f.value);
        });

        if (unManagedTasksFetchUrls.current[key] === url.toString()) return;

        unManagedTasksFetchUrls.current[key] = url.toString();

        await httpClient.fetchAll(url.toString(), { sideloadOrders: true }).then((res) => {
          if (key === "completed") {
            setCompletedTasks(res.data);
          } else if (key === "cancelled") {
            setCancelledTasks(res.data);
          }
        });
      })
    );

    setCachedTasks({});
    setTasksURL(initialLoadURL);
  };

  useUnload((e) => {
    // Prevents browser unload if commands resolution is pending
    e.preventDefault();

    const confirmationMessage = t`Changes you have made might not be saved`;
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }, override.length > 0);

  const isClient = get(configuration, "permissions.is_client");

  const groupedTasks = onGroupTasks(
    isClient,
    cachedTasks,
    tasksLoading,
    workers,
    routes,
    override,
    appData?.isUserOnlyAWorker,
    hideUnassignedTasks
  );

  const { filteredTasks, filters, onApply, loadingFilters } = useStoreFilters(filterKey, onApplyFilters, groupedTasks);

  return {
    isLoading: tasksLoading || loadingFilters,
    tasks: tasksLoading ? [] : filteredTasks,
    metaFieldChoices,
    filters: {
      data: filters,
      onApply,
      onSearch,
    },
    onMutateNewTasks: onMutateNewTasks,
    onMutateGroupedTasks: mutateTasks,
  };
};

export { useFetchTasks };
