import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "hooks/debounce";

const useGooglePlacesAPI = (search) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const service = useMemo(() => new google.maps.places.AutocompleteService(), []);
  const debouncedSearch = useDebounce(search, 200);

  useEffect(() => {
    if (service && debouncedSearch) {
      setLoading(true);
      const request = {
        input: debouncedSearch,
        strictBounds: false,
      };

      service.getPlacePredictions(request, (data) => {
        const places = data?.map((place) => {
          return {
            placeId: place.place_id,
            properties: {
              formatted_address: place.description,
            },
          };
        });
        setLoading(false);
        setSuggestions(places);
      });
    }
  }, [service, debouncedSearch]);

  return { suggestions, loading };
};

export { useGooglePlacesAPI };
